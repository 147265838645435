import { useCallback, useState } from 'react';
import { useRouter } from 'next/navigation';
import type { Order } from '@wilm/shared-types/cart';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import toast from 'react-hot-toast';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import type { TermsError } from 'frontastic/hooks/useCart/types';
import useStudioPaymentsSettings from 'frontastic/hooks/useStudioPaymentsSettings';
import { useCheckout } from '../provider';

const usePurchase = () => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

    const { transaction, data, hasOutOfStockItems, orderCart, isCartInventoryAvailable } = useCart();
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isDicountCodeError, setIsDicountCodeError] = useState(false);
    const { paymentData, setProcessing, makeInvoicePayment, makePayment, onSuccessfulPayment, makeInstalmentPayment } = useCheckout();
    const { account } = useAccount();
    const router = useRouter();
    const { earlyTokenizationExclusions } = useStudioPaymentsSettings();

    const handleStaticPayment = useCallback(
        async (type: PaymentMethodType) => {
            let payment = null;
            if (type === PaymentMethodType.INVOICE) {
                payment = await makeInvoicePayment(transaction.total.centAmount, transaction.total.currencyCode);
            }
            if (type === PaymentMethodType.INSTALMENT) {
                if (paymentData.type !== PaymentMethodType.INSTALMENT) return;
                payment = await makeInstalmentPayment(
                    transaction.total.centAmount,
                    transaction.total.currencyCode,
                    paymentData.paymentInstalmentOption
                );
            }

            if (!payment || payment?.isError) {
                setProcessing(false);
                toast.error(
                    formatCheckoutMessage({
                        id: 'payment.failed',
                        defaultMessage: 'We could not process your payment, please try again later.'
                    })
                );
                return;
            }

            const response = await orderCart(isTermsAccepted, paymentData.type === PaymentMethodType.INSTALMENT);
            const termsError = response as TermsError;

            if (termsError?.error?.message === 'termsNotAccepted') {
                toast.error(
                    formatCheckoutMessage({
                        id: 'terms.not.accepted',
                        defaultMessage: 'Please accept Terms and Conditions to proceed.'
                    })
                );
                return;
            }

            if (termsError?.error?.message === 'Error: discountCodesNotAPpplicable') {
                setIsDicountCodeError(true);
                return;
            }

            if (termsError?.isError) {
                toast.error(
                    formatCheckoutMessage({
                        id: 'order.failed',
                        defaultMessage: 'We could not process your order, please try again later.'
                    })
                );
                return;
            }

            const order = response as Order;
            onSuccessfulPayment(order);
        },
        [
            isTermsAccepted,
            orderCart,
            formatCheckoutMessage,
            transaction.total.centAmount,
            transaction.total.currencyCode,
            onSuccessfulPayment
        ]
    );

    const purchase = useCallback(async () => {
        if (!data?.shippingAddress || !data?.billingAddress) return;

        if (
            !transaction.total.centAmount ||
            ![PaymentMethodType.INVOICE, PaymentMethodType.CARD, PaymentMethodType.INSTALMENT].includes(paymentData.type)
        ) {
            toast.error(
                formatCheckoutMessage({
                    id: 'payment.failed',
                    defaultMessage: 'We could not process your payment, please try again later.'
                })
            );
            setProcessing(false);

            return;
        }

        if (!isTermsAccepted) {
            toast.error(
                formatCheckoutMessage({
                    id: 'terms.not.accepted',
                    defaultMessage: 'Please accept Terms and Conditions to proceed.'
                })
            );
            setProcessing(false);
            return;
        }

        setProcessing(true);

        const cartHasInventoryError = !(await isCartInventoryAvailable());
        if (cartHasInventoryError) {
            //redirect to cart page
            router.push('/?inventoryError=true');
            return;
        }

        if (paymentData.type === PaymentMethodType.INVOICE || paymentData.type === PaymentMethodType.INSTALMENT) {
            await handleStaticPayment(paymentData.type);
        } else if (paymentData.type === PaymentMethodType.CARD) {
            await makePayment(
                isTermsAccepted,
                earlyTokenizationExclusions && earlyTokenizationExclusions.length > 0
                    ? earlyTokenizationExclusions.includes(transaction.total.currencyCode)
                    : false
            );
        }
    }, [
        hasOutOfStockItems,
        makeInvoicePayment,
        transaction.total,
        paymentData.type,
        account?.accountId,
        data,
        formatCheckoutMessage,
        isTermsAccepted,
        earlyTokenizationExclusions
    ]);

    return { purchase, isTermsAccepted, setIsTermsAccepted, isDicountCodeError };
};

export default usePurchase;
