import React, { useEffect, useState } from 'react';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import Typography from 'components/commercetools-ui/atoms/typography';
import ErrorModal from 'components/commercetools-ui/organisms/checkout/components/error-modal';
import useResolveCCImage, { useResolveCardType } from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import { useCheckout } from 'components/commercetools-ui/organisms/checkout/provider';
import type { PaymentData, DeviceDataParams } from 'components/commercetools-ui/organisms/checkout/provider/payment/types';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import Fields from './fields';

interface Props {
    onValid: () => void;
    onInvalid: () => void;
    selected: boolean;
}

const CreditCard: React.FC<Props> = ({ onValid, onInvalid, selected }) => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { transaction, isLoading: cartIsLoading, taxed } = useCart();
    const { account } = useAccount();
    const resolveCardType = useResolveCardType();
    const resolveImageByNumber = useResolveCCImage();
    const {
        paymentData,
        setPaymentData,
        addPaymentWithToken,
        updatePaymentToTriggerAuthFlow,
        createPaymentToGetTokenContext,
        resetMicroform
    } = useCheckout();
    // const [paymentId, setPaymentId] = useState('');
    const [submitForm, setSubmitForm] = useState(false);

    const [payWithSavedCard, setPayWithSavedCard] = useState(true);
    const [paymentIsLoading, setPaymentIsLoading] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [customError, setCustomError] = useState('');

    const onFieldsInvalid = () => {
        setSubmitForm(false);
        onInvalid();
    };
    useEffect(() => {
        if (
            selected &&
            (!(account?.token?.paymentToken && payWithSavedCard) ||
                (transaction.total.currencyCode !== account?.token?.currencyCode && !cartIsLoading)) &&
            taxed
        ) {
            createPaymentToGetTokenContext(onFieldsInvalid, false);
        }
    }, [
        payWithSavedCard,
        selected,
        transaction.total.currencyCode,
        account?.token?.paymentToken,
        account?.token?.currencyCode,
        cartIsLoading,
        taxed
    ]);

    useEffect(() => {
        if (resetMicroform) {
            createPaymentToGetTokenContext(onFieldsInvalid, resetMicroform);
        }
    }, [resetMicroform]);

    useEffect(() => {
        if (!selected) return;
        const payButton = document.querySelector('#pay-button');

        if (account?.token?.paymentToken && payWithSavedCard && transaction.total.currencyCode === account?.token.currencyCode) {
            payButton?.removeEventListener('click', payButtonListener);
            payButton?.addEventListener('click', payWithToken);
        } else {
            payButton?.removeEventListener('click', payWithToken);
            payButton?.addEventListener('click', payButtonListener);
        }

        return () => {
            payButton?.removeEventListener('click', payButtonListener);
            payButton?.removeEventListener('click', payWithToken);
        };
    }, [selected, paymentData, payWithSavedCard, payWithToken]);

    function payWithToken() {
        (async () => {
            setPaymentIsLoading(true);
            const payment = await addPaymentWithToken(transaction.total.centAmount, transaction.total.currencyCode);
            if (payment) {
                let deviceData: DeviceDataParams = {};
                if (
                    payment.paymentMethod === 'creditCardWithPayerAuthentication' &&
                    payment.deviceDataParams &&
                    payment.deviceDataParams.isv_requestJwt !== ''
                ) {
                    const updatePayment = await updatePaymentToTriggerAuthFlow(payment.id);

                    deviceData = updatePayment.deviceDataParams as DeviceDataParams;
                    if (!Object.keys(deviceData).length) {
                        setIsErrorModalOpen(true);
                        setCustomError(
                            formatCheckoutMessage({
                                id: 'payment.failed.no.device.data',
                                defaultMessage: 'Something went wrong please try later or use another card'
                            })
                        );
                        setPaymentIsLoading(false);
                        return;
                    }
                }
                setPaymentData({
                    ...paymentData,
                    paymentId: payment.id,
                    maskedCard: payment.maskedCard,
                    expiryMonth: payment.expiryMonth,
                    expiryYear: payment.expiryYear,
                    paymentMethod: payment.paymentMethod,
                    deviceDataParams: deviceData,
                    paymentCardType: resolveCardType(payment.maskedCard),
                    withToken: true
                } as PaymentData);
                setPaymentIsLoading(false);
                onValid();
            } else {
                setPaymentIsLoading(false);
                setIsErrorModalOpen(true);
                setCustomError('');
            }
        })();
    }

    function payButtonListener() {
        setSubmitForm(true);
    }

    const loader = () => {
        return (
            <>
                <Overlay />
                <div
                    style={{ width: '50px', height: '50px' }}
                    className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white"
                >
                    <div className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white">
                        <LoadingIcon className="fill-gray-700" />
                    </div>
                </div>
            </>
        );
    };

    if (cartIsLoading || !taxed) return <></>;

    return account?.token?.paymentToken && payWithSavedCard && transaction.total.currencyCode === account?.token.currencyCode ? (
        <>
            {paymentIsLoading && loader()}
            <div className="pb-16 pt-12 md:max-w-[436px]">
                {formatCheckoutMessage({
                    id: 'checkout.pay.with.saved.token',
                    defaultMessage: 'Pay with your saved card'
                })}
            </div>

            <div className="mt-16 flex items-center justify-between rounded-md border px-16 py-12 lg:p-24">
                <div className="flex items-center">
                    {resolveImageByNumber(account.token.cardNumber) && (
                        <Image className="h-fit w-32" src={resolveImageByNumber(account.token.cardNumber)} />
                    )}
                    <Typography className="ml-16 text-14 text-primary-black">
                        {account?.token?.cardNumber && account.token.cardExpiryMonth && account.token.cardExpiryYear
                            ? `...${account.token.cardNumber.substring(
                                  account.token.cardNumber.length - 4,
                                  account.token.cardNumber.length
                              )} ${account.token.cardExpiryMonth}/${account.token.cardExpiryYear}`
                            : formatCheckoutMessage({
                                  id: 'checkout.my.saved.card',
                                  defaultMessage: 'My saved card'
                              })}
                    </Typography>
                </div>
            </div>
            <button className="mt-32 cursor-pointer underline md:max-w-[436px]" onClick={() => setPayWithSavedCard(false)}>
                {formatCheckoutMessage({
                    id: 'checkout.add.new.card',
                    defaultMessage: 'Pay with new card'
                })}
            </button>
            <ErrorModal isErrorModalOpen={isErrorModalOpen} setIsErrorModalOpen={setIsErrorModalOpen} customError={customError} />
        </>
    ) : (
        <div className="md:max-w-[436px] md:pl-6">
            <Fields
                onInvalid={onFieldsInvalid}
                onValid={() => {
                    setSubmitForm(false);
                    setPaymentIsLoading(false);
                    onValid();
                }}
                submitForm={submitForm}
                currency={transaction.total.currencyCode}
            />
        </div>
    );
};

export default CreditCard;
